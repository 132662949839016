import React, {useEffect, useState} from "react"
import styled from "styled-components";
import { navigate } from 'gatsby';
import { StoreContext } from "../../context/store-context"
import { Layout } from "../../components/Layout/layout"
import AccountLayout from "./account-layout";
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const OrderWrapper = styled.div` 
  display: flex;
  justify-content: flex-end;
  width: 100%;
  ${fontstack.default}
  ${type('m-body')}
  ${media.large`
    ${type('body')}
  `}
`

const OrderInner = styled.div`
    width: 100%;
`

const Table = styled.div`
  position: relative;
  width: 100%;
`

const TableRow = styled.div`
  border-bottom: 1px solid #383B23;
`

const HeadRow = styled(TableRow)` 
  border-top: none;
  ${media.large`
    border-bottom: 1px solid #383B23;
  `}
`

const SummaryRow = styled.div`
  display: flex;
  padding: 15px 0;
  box-sizing: border-box;
  justify-content: space-between;
  user-select: none;

  ${media.large`
    padding: 20px 30px;
  `}
`

const HeadSummaryRow = styled(SummaryRow)` 
  margin: 0 0 25px;
  ${media.large`
    margin: 0;
  `}
`

const OrderSummaryRow = styled(SummaryRow)`
   cursor: pointer;
  transition: all .2s ease;
  border-bottom: ${props => props.active ? '1px solid #383B23' : 'none'};
   &:hover {
    color: #F3EFD7;
    background: #383B23;
   }
`

const TableColOne = styled.div`
  text-align: left;
  flex: 1;
`

const TableColTwo = styled.div`
  text-align: left;
  width: 90px;
  ${media.large`
    width: 200px;
  `}
`

const OrderArea = styled.div`
  background: #383B2330;
  color: #F3EFD7;
  overflow: hidden;
  height: ${props => props.active ? 'auto' : '0'};
  margin: 0 -20px;
  box-sizing: border-box;
  padding: 0 0 15px;

  ${media.large`
    width: 100%;
    margin: 0;
    padding: 0;
  `}
`

const OrderDivide = styled.div` 
  padding: 0 20px;
  display: flex;
  justify-content: stretch;
  margin: 15px 0;

  &:before {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background: #383B23;
  }
 
  ${media.large`
    padding: 0 30px;
    margin: 0;
  `}
`

const OrderRow = styled.div` 
  display: flex;
  padding: 0 20px;
  margin: 20px 0 15px;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  ${fontstack.default}
  ${type('caption')}
  color: #383B23;


  ${media.large`
    ${type('body')}
    padding: 0 30px;
  `}
`

const LineItemRow = styled(OrderRow)` 
  margin: 0 0 5px;
  ${media.large`
    margin: 15px 0;
  `}
  

`

const TotalsRow = styled(OrderRow)` 
  margin: 0 0 5px;
  ${fontstack.default}
  ${type('caption')}
  font-weight: 500;
  
  ${media.large`
    ${type('body')}
    font-weight: 400;
    margin: 15px 0;
  `}

`

const HeaderRow = styled(OrderRow)` 
  color: #F3EFD7;
`

const DateWrap = styled.span` 
  text-transform: uppercase;
`

const HeadLabel = styled.h4`
  ${fontstack.default}
  ${type('large')}
  color: #383B23;
`

export default function OrdersPage() {
  const { getCurrentUser, getCustomerObject, loading, toggleLogin } = React.useContext(StoreContext);
  const [ Customer, setCustomer ] = useState(null);
  const [ Active, setActive ] = useState(null);

  useEffect(()=> {

    getCustomerObject()
      .then(res => setCustomer(res.data.customer))
      .catch((e)=>{
        navigate("/")
        toggleLogin();
      });
  },[])

  console.log(Customer);
  return (
    <Layout>
      <AccountLayout active="/orders">
        <OrderWrapper>
          <OrderInner>
            <Table>
              <HeadRow>
                <HeadSummaryRow>
                  <TableColOne>Date</TableColOne>
                  <TableColTwo>Charge</TableColTwo>
                </HeadSummaryRow>
              </HeadRow>
              { Customer && Customer.orders.edges.length > 0 ? Customer.orders.edges.map((object, index) => {
                const order = object.node;
                const price = order.currentTotalPrice;
                const subtotal = order.currentSubtotalPrice;
                const shipping = order.totalShippingPriceV2;
                const discount = order.discountApplications.edges.length > 0 ?  order.discountApplications.edges[0].node.value : null;
                const processed = new Date(order.processedAt);
                const month = processed.toLocaleString('default', { month: 'long' });

                const lineItems = order.lineItems.edges;
                return (
                  <TableRow>
                    <OrderSummaryRow active={Active === index ? true : false} onClick={()=>{ if(Active === index) { setActive(null) } else { setActive(index)}  }}> 
                      <TableColOne><DateWrap>{`${month} ${processed.getDate()}, ${processed.getFullYear()}`}</DateWrap></TableColOne>
                      <TableColTwo>{`${price.currencyCode}${price.amount}`}</TableColTwo>
                    </OrderSummaryRow>
                    <OrderArea active={Active === index ? true : false}>
                      <HeaderRow>
                        <TableColOne>Product</TableColOne>
                        <TableColTwo>Quantity</TableColTwo>
                        <TableColTwo>Amount</TableColTwo>
                      </HeaderRow>
                      { lineItems.length > 0 ? lineItems.map((item) => {
                        const line = item.node;
                        const price = line.discountedTotalPrice;
                        return <LineItemRow>
                        <TableColOne>{line.title}</TableColOne>
                        <TableColTwo>{line.currentQuantity}</TableColTwo>
                        <TableColTwo>{`${price.currencyCode}${price.amount}`}</TableColTwo>
                      </LineItemRow>
                      }) : null }
                      <OrderDivide/>
                      { discount ? 
                        <TotalsRow>
                          <TableColOne>Discount</TableColOne>
                          {discount.amount}
                          <TableColTwo>{discount.amount ? `-${price.currencyCode}${discount.amount}` :  discount.percentage ? `-${discount.percentage}%` : ''}</TableColTwo>
                        </TotalsRow> : null }
                      
                      <TotalsRow>
                        <TableColOne>Subtotal</TableColOne>
                        <TableColTwo>{`${price.currencyCode}${subtotal.amount}`}</TableColTwo>
                      </TotalsRow>
                      <TotalsRow>
                        <TableColOne>Shipping</TableColOne>
                        <TableColTwo>{`${price.currencyCode}${shipping.amount}`}</TableColTwo>
                      </TotalsRow>
                      <TotalsRow>
                        <TableColOne>Total</TableColOne>
                        <TableColTwo>{`${price.currencyCode}${price.amount}`}</TableColTwo>
                      </TotalsRow>
                    </OrderArea>
                  </TableRow>
                )
              }) : null}
            </Table>
          </OrderInner>
        </OrderWrapper>
      </AccountLayout>
    </Layout>
  )
}
